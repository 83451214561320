.dashboard-main {
    padding: 20px;
  }
  .dashboard-main h4 {
    font-size: 30px;
    color: #FF7F11;
    font-family: 'Roboto Slab';
  }
  
  .dashboard {
    background-color: #f7f6fb;
    margin-top: 80px; 
    border-radius: 20px;
    box-shadow: 0 0 2px;
  }
  
  .header-card {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid #00658f;
    border-radius: 10px;
  }
  
  table,tr,th,td {
    padding: 10px;
    font-family: 'Libre Baskerville', sans-serif;
    color: #0b6082;
    font-weight: 600;
  }
  
  .header-card h2 {
    line-height: 31px;
    font-size: 45px;
    margin-top: 5px;
    margin-right: 10px;
  }
  
  .table-main {
    margin: 15px;
    overflow: auto;
     padding: 15px; 
    border-radius: 15px;
    border: 1px solid #ddd;
  }
  
  .table-main thead {
    background-color: #ddd;
    border-radius: 18px;
    color: #00658f;
  }
  
  .table-main table {
    width: 100%;
    white-space: nowrap;
  }
  
  .dash-footer p {
    font-size: 14px;
    font-weight: 600;
    padding: 40px 0 40px 20px;
  }
  /* .Orders-btn button.dash-btn {
    background-color: #00658f;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 11px;
    font-size: 16px;
    font-weight: 600;
  } */
  
  /*-------------------------------- sidebar  --------------------------------------------- */
  .desh-main {
    background-color: #1D3557;
  }
  .desh-main ul#menu li a.nav-link {
    font-size: 20px;
    font-weight: 600;
    color: #FF7F11;
    margin-top: 20px;
    font-family: 'Roboto Slab', sans-serif;
  }
  .desh-main ul#menu li a.nav-link :hover i .fa {
    color: #00658f;
  } 
  .desh-main ul#menu li a.nav-link span:focus {
    outline: none;
    border: none;
  }
  .desh-main ul#menu li a.nav-link:active {
    font-size: 20px;
    color: #fff;
    background-color: #00658f;
    padding: 10px !important;
    transition: 0.3s;
    color: #00658f;
  }
  .dropdown{
    padding-bottom: 1.5rem !important;
    font-size: 17px;
    margin-left: 2px;
  }
  @media (max-width:575px) {
    .desh-main ul#menu li a.nav-link:active {
      font-size: 20px;
      color: #fff;
      background-color: #00658f;
      padding: 4px !important;
      transition: 0.3s;
    }
  }
  
  /*------------------------------ responsive ------------------------------------- */
  
  
  .dashboard-contain .desh-main .text-decoration-none {
    margin: 0 !important;
    width: 150px;
    background: #fff;
    border-radius: 12px;
    text-align: center;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  
  .dashboard-contain .desh-main .text-decoration-none span img {
    width: 70px;
  }
  
  .dashboard-contain .col.py-3 {
    padding: 34px !important;
  }
  
  .dashboard-contain .desh-main .text-decoration-none span {
    display: block !important;
    width: 100%;
  }
  
  .dashboard-contain #menu li {
    width: 100%;
  }
  
  .dashboard-contain #menu li .nav-link {
    margin: 0 !important;
    padding: 15px 15px !important;
    border-radius: 0;
    border-bottom: 1px solid;
  }
  .desh-main ul#menu li:hover a.nav-link {
    background-color: #fff;
    color: #00658f;
  }
  .desh-main ul#menu li a.nav-link .fa {
    margin-right: 8px;
  }
  
  .dashboard-contain #menu {
    width: 100%;
    padding: 26px 8px;
  }
  
  
  .dashboard-contain .dashboard {
    background-color: #fbfbfb;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    border: 0 !important;
    padding: 20px;
  }
  /* .dashboard-contain .dashboard button {
    background-color: #00658f;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    padding: 8px 25px;
  } */
  
  .dashboard-contain .dashboard .dashboard-main {
    padding: 0px;
  }
  
  
  .dashboard-contain .dashboard .table-main {
    margin: 15px 0px;
  }
  
  .dashboard-contain .dashboard .table-main table #renew {
    margin: 0;
  }
  .dashboard-contain .dashboard .dash-footer {
    margin: 20px 0px 0px;
  }
  .dashboard-contain .dashboard .dash-footer p {
    padding-bottom: 0;
    padding-left: 0;
    color: #FF7F11;
  }
  
  .dashboard-contain .dashboard .table-main table th {
    color: #2b2b2b;
    background: #f5f5f5;
  }
  
  .dashboard-contain .dashboard .table-main table td.sub-price {
    color: #FF7F11;
    font-size: 16px;
    font-family: 'Roboto Slab', ;
    font-weight: 600;
  }
  
  /* .signout-dashbaord .btn.btn-primary {
    background: #fff;
    color: #00658f;
    border: 1px solid #00658f;
  }
  .signout-dashbaord .btn.btn-primary:hover 
  {
    background: #00658f;
    color: #fff;
    border: 1px solid #fff;
  }
  .signout-dashbaord .btn.btn-primary .fa::before
  {
    margin-right:5px ;
  } */
  
  #GFG .modal-header img {
    width: 46px;
  }
  
  
  #GFG .modal-body .popup-card {
    min-height: 320px;
    padding-bottom: 64px;
    position: relative;
    text-align: center;
  }
  
  #GFG .modal-body .popup-card h4 {
    font-size: 20px;
  }
  
  /* #GFG .modal-body .popup-card .btn.popup-btn {
    position: absolute;
    bottom: 15px;
    width: 89%;
    padding: 6px 18px;
    white-space: nowrap;
    left: 0;
    right: 0;
    display: inline-block;
    margin: auto;
  }
  
  #GFG .modal-body .popup-footer .btn {
    background: #01b399;
    color: #fff;
    border: 0;
    padding: 8px 26px 8px 36px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
  } */
  
  #GFG .modal-body .popup-card.active h4 {
    color: #fff;
  }
  
  #GFG .modal-body .popup-card.active p {
    color: #fff;
  }
  
  /* #GFG .modal-body .popup-card.active .btn.popup-btn {
    background: #fff;
    color: #00658f;
  } */
  
  
  .account-container {
    width: 300px;
    margin: auto;
  }
  .input-group {
    margin-bottom: 15px;
  }
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  .input-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  .error-message {
    color: red;
    font-size: 12px;
  }


  .signout_button {
    background-color: #FF7F11;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-family:Roboto Slab;
    height: 46px;
    line-height: 30px;
    text-transform: uppercase;
    width: 151px;
  }


  .pop_up.btn.btn-contained {
    background: #FF7F11;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-family: barlow-bold;
    height: 46px;
    line-height: 30px;
    text-transform: uppercase;
    width: 151px;
    margin-right: 50%;
  }

